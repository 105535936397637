import { FC, useState } from "react";
import styles from "./cardApartment.module.scss";
import Modal from "../modal/Modal";
import { ModalResidences } from "../../modal/residences";

interface ICardApartmentProps {
  id?: number;
  img?: string;
  title: string;
  block: string;
  totalRooms: string;
  square: string;
}

export const CardApartment: FC<ICardApartmentProps> = (props) => {
  const [modal, setModal] = useState(false);

  const onclose = () => {
    setModal(false);
  };
  return (
    <>
      <Modal
        visible={modal}
        title={""}
        content={
          <ModalResidences onclose = {onclose} img = {props.img} title={props.title} block={props.block} totalRooms={props.totalRooms} square={props.square} />
        }
        footer={""}
        onClose={onclose}
      />
      <div className={styles.root} onClick={() => setModal(true)}>
        <div className={styles.card}>
          <img className={styles.img} src={props.img} alt="" />
          <div className={styles.title2}>{props.title}</div>
          <div className={styles.wrapProp}>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.wrapText}>
              <div className={styles.block}>
                Блок
                <div className={styles.color}>{props.block}</div>
              </div>
              <div className={styles.block}>
                Всего комнат
                <div className={styles.color}> {props.totalRooms}</div>
              </div>
              <div className={styles.block}>
                Квадратура
                <div className={styles.color}> {props.square} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
