import React, { FC } from "react";
import styles from "./construction.module.scss";
// import { RModalImages } from "react-modal-images";
import DATA from "../../data";
interface IConstructionProps {}

export const Construction: FC<IConstructionProps> = (props) => {


  return (
    <div className={styles.root}>
       
      <h3 className={styles.title}>Ход строительства</h3>
      <div className={styles.container}>
       {DATA.constructionData.map((data) =>
       
      <div className={styles.card}>
        {/* <RModalImages

          medium={data.icon}
          large={data.icon}
          alt={"dadasd"}
          small={data.icon}
    
        /> */}
        <span className={styles.data}>
                {data.data}
            </span>      </div>
       )
       }
       </div>
    </div>
  );
};
