import styles from "./Header.module.scss";
import classNames from "classnames";
import logo from "../../../assets/logo.png";
import { Link } from "react-scroll";
import Button from "../../UI/button/button";
import UI from "../../UI";
// import { useState } from "react";
import { showModal } from "../../../store/actions";
import { connect,ConnectedProps } from "react-redux";

const mapDispatchToProps = {
  dispatchShowModal: showModal,
};

const connector = connect(undefined, mapDispatchToProps);

type HeaderProps = {} & ConnectedProps<typeof connector>;



function Header(props: HeaderProps) {
  // const { dispatchShowModal } = props;
  // const [showModal, setShowModal] = useState(false);
  return (
    <>



    <div className={classNames(styles.root)}>
      <header className={styles.header}>
        <div className={styles.wrap}>
          <a href="/" className={styles.logo}>
            <img
            className={styles.widthLogo}
              src={logo}
              alt="Somoni Residence"
            />
          </a>
          <div className={styles.menu}>
          <ul className={styles.None} >
            <li>
              <Link
                activeClass="active"
                to="resident"
                spy={true}
                smooth={true}
                offset={-70}
                duration={600}
              >
                О жилом комплексе
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="advantages"
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
              >
                Преимущества
              </Link>
            </li>
            <li>
            <Link
                activeClass="active"
                to="gallery"
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
              >
              Галерея
              </Link>
            </li>
            <li>
            <Link
                activeClass="active"
                to="apartments"
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
              >
              Квартиры
              </Link>
            </li>
          </ul>
          <span className={styles.buttons}>
          <Link
                activeClass="active"
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
              >
          <Button   className={styles.button}>Заказать звонок</Button>
            </Link>
            </span>
            </div>
            
        </div>
      </header>
    
    </div>
    </>
  );
}

export default connector(Header);
