import apartmentImage from '../assets/apartment/aprtment.svg'

export const apartmentSecondData = [
    {
        id:1,
        img: "/img/apartment/second/01.png", 
        title: '2 комнаты',
        block: 'А',
        totalRooms: '2 комнаты',
        square: '78,15м²'
    },
    {
        id:2,
        img: "img/apartment/second/02.png", 
        title: '2 комнаты',
        block: 'А',
        totalRooms: '2 комнаты',
        square: '67,40м²'
    },
    {
        id:3,
        img: "img/apartment/second/03.png", 
        title: '2 комнаты',
        block: 'Б',
        totalRooms: '2 комнаты',
        square: '72,85м²'
    },
    {
        id:4,
        img: "img/apartment/second/04.png", 
        title: '2 комнаты',
        block: 'Б',
        totalRooms: '2 комнаты',
        square: '77,74м²'
    },
    {
        id:5,
        img: "img/apartment/second/05.png", 
        title: '2 комнаты',
        block: 'Б',
        totalRooms: '2 комнаты',
        square: '90,40м²'
    },
    {
        id:6,
        img: "img/apartment/second/06.png", 
        title: '2 комнаты',
        block: 'Б',
        totalRooms: '2 комнаты',
        square: '77,90м²'
    },
    {
        id:7,
        img: "img/apartment/second/07.png", 
        title: '2 комнаты',
        block: 'Б',
        totalRooms: '2 комнаты',
        square: '77,35м²'
    },
    {
        id:8,
        img: "img/apartment/second/08.png", 
        title: '2 комнаты',
        block: 'В',
        totalRooms: '2 комнаты',
        square: '67,40м²'
    },
    {
        id:9,
        img: "img/apartment/second/09.png", 
        title: '2 комнаты',
        block: 'В',
        totalRooms: '2 комнаты',
        square: '78,15м²'
    },
]