export const shopsSliderData = [
    {
        id:2,
        img: '/img/02.jpg',
    },
    {
        id:7,
        img: '/img/07.jpg',
    },

]