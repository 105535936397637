import { FC } from "react";
import styles from './advantages.module.scss'
import UI from "../UI";
import DATA from "../../data";
import { AnimationOnScroll } from "react-animation-on-scroll";

interface IAdvantagesProps {};

export const Advantages: FC<IAdvantagesProps> = (props) => {
    return (
        <>
        
        <div id="advantages" className={styles.root}>
             <span className={styles.title}>Преимущества</span>
             <div className={styles.cards}>
                {DATA.advantagesCardData.map(card => (
                    

                    <UI.Card  icon = {card.icon} title = {card.title} subtitle = {card.subtitle} />
                
                ))}
             </div>
             <div className={styles.logoback}></div>
        </div>
        
        </>
    );
}
