import { banerCardData } from "./banerCardData";
import {advantagesCardData } from "./advantagesCardData";
import { contactCardData } from "./contactCardData";
import { complexSliderData } from "./ComplexSliderData";
import {interSliderData} from "./interSliderData";
import {parkingSliderData} from "./parkingSliderData";
import {shopsSliderData} from "./shopsSliderData";
import {apartmentSecondData} from "./apartmentSecondData";
import { apartmentThreeData } from "./apartmentThreeData";
import {constructionData} from "./constructionData";
const DATA = Object.freeze(
    Object.seal({
        banerCardData,
        advantagesCardData,
        contactCardData,
        complexSliderData,
        interSliderData,
        parkingSliderData,
        shopsSliderData,
        apartmentSecondData,
        apartmentThreeData,
        constructionData
    })
)

export default DATA;