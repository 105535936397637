import { FC } from "react";
import styles from "./card.module.scss"
import { AnimationOnScroll } from "react-animation-on-scroll";

interface ICardProps {
    id?: number;
    icon: string;
    title: string;
    subtitle: string;
    contact?: boolean
    ssilka?: string;
};

export const Card: FC<ICardProps> = (props) => {
    return (
        // <AnimationOnScroll animateIn="animate__zoomIn">
        <div className= { props.contact ? styles.cardContact :  styles.card}>
            <img className={styles.icon} src={props.icon} alt="" />
            <div className = {styles.title}>
                {props.title}
            </div>   
            <div className = {styles.subTitle}>
             {props.subtitle}
            </div>
        </div>
        // </AnimationOnScroll>
    );
}
