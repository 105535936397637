
import buildings from '../assets/icon/buildings.svg'

import playground from '../assets/icon/playground.svg'
import video from '../assets/icon/video.svg'
import layouts from '../assets/icon/layouts.svg'
import parking from '../assets/icon/parking.svg'
import infrastructure from '../assets/icon/infrastructure.svg'

export const advantagesCardData = [
    {
        id:1,
        icon: buildings,
        title: 'Двор на платформе',
        subtitle: 'Тёплые вечера, в уютном дворе, станут ещё приятнее в окружении близких людей'
    },
    {
        id:2,
        icon:playground,
        title: 'Детская площадка',
        subtitle: 'Обустроенная детская территория станет безопасным и комфортным местом для физического развития юных жильцов'
    },
    {
        id:3,
        icon: video,
        title: 'Видеонаблюдение',
        subtitle: 'Круглосуточная охрана и камеры видеонаблюдения обеспечат дополнительную безопасность на всей территории ЖК'
    },
    {
        id:4,
        icon: layouts,
        title: 'Улучшенные планировки',
        subtitle: 'Просторные и эргономичные планировки позволят реализовать ваши самые смелые идеи',
    },
    {
        id:5,
        icon:parking,
        title: 'Паркинг',
        subtitle: 'Подземный и летний паркинги станут гарантией сохранности вашего автомобиля'
    },
    {
        id:6,
        icon: infrastructure,
        title: 'Развитая инфраструктура',
        subtitle: 'Удобное расположение в центре города обеспечивает шаговую доступность от всех важнейших социальных и торговых объектов'
    },
]