import { FC } from "react";
import styles from "./complex.module.scss";

import UI from "../UI";
import DATA from "../../data";
import { AnimationOnScroll } from "react-animation-on-scroll";

interface IComplexProps {}

export const Complex: FC<IComplexProps> = (props) => {
  return (
    <AnimationOnScroll animateIn="animate__fadeIn">
      <div id="gallery" className={styles.root}>
        <div className={styles.title}>О нашем комплексе</div>
        <div className={styles.container}>
          <UI.Slider title="first" img={DATA.complexSliderData} />
          <div className={styles.wrap}>
            <span className={styles.subTitle}>ЭКСТЕРЬЕР</span>
            <span className={styles.text}>
              Величественный экстерьер Жилого Комплекса прекрасно впишется в
              архитектуру нашей столицы, не только днем, но и ночью, благодаря
              освещению по всему периметру комплекса. Качественный фасадный
              камень, колоны у основания, абстрактный узор здания станет
              украшением и идеальным дополнением будущего городского ландшафта.
            </span>
          </div>
        </div>
        <div className={styles.containerInter}>
          <div className={styles.wrap}>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <span className={styles.subTitle}>ИНТЕРЬЕР</span>{" "}
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <span className={styles.text}>
                Уникальный интерьер внутри жилого комплекса - входная часть,
                лобби, подъезды и лифты - создадут ощущение тепла, домашнего
                уюта и комфорта с самого порога. Высококлассные материалы
                используемые при отделке, качественное освещение обеспечат
                безопасность в любое время суток и времени.
              </span>
            </AnimationOnScroll>
          </div>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <UI.Slider title="second" img={DATA.interSliderData} />
          </AnimationOnScroll>
        </div>
        <div className={styles.container}>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <UI.Slider title="three" img={DATA.parkingSliderData} />{" "}
          </AnimationOnScroll>
          <div className={styles.wrap}>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <span className={styles.subTitle}>ДВОР И ПАРКОВКА</span>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <span className={styles.text}>
                Жилой комплекс «Somoni Residence» оснащен просторной подземной
                парковкой, а также крытой летней парковкой, которая будет
                находиться во дворе под платформой с расположенными на ней
                детской площадкой, уютными беседками, озеленением и фонтаном.
              </span>
            </AnimationOnScroll>
          </div>
        </div>
        <div className={styles.containerlast}>
          <div className={styles.wrap}>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <span className={styles.subTitle}>МАГАЗИНЫ И ЛАВКИ</span>{" "}
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <span className={styles.text}>
                На первом этаже жилого комплекса будут расположены торговые
                помещения, необходимые объекты, а также брендовые
                магазины будут в шаговой доступности для наших будущих жильцов.
              </span>
            </AnimationOnScroll>
          </div>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <UI.Slider title="four" img={DATA.shopsSliderData} />
          </AnimationOnScroll>
        </div>
      </div>
    </AnimationOnScroll>
  );
};
