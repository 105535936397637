import { ReactNode } from 'react';
import styles from './DesktopLayout.module.scss'
import classNames from 'classnames';

interface LayoutProps {
  children: ReactNode,
  className?: string
}

export default function DesktopLayout({
    children, className }: LayoutProps) {
  return (
    <>
  <div className = {classNames(className, styles.root)}>
      <div className={styles.wrap}>
        {children}
        </div>
  </div>
  </>
  );
}
