
import React, { ReactNode, ReactElement, StrictMode, useEffect, useState } from  'react'
import styles from './Modal.module.scss'
import classNames from 'classnames'

interface ModalProps {
    visible: boolean
    title: string
    content: ReactElement | string
    footer: ReactNode | string
    onClose: () => void
    className?: string
}

export default function Modal({ 
     visible = false,
    title = '',
    content = '',
    footer = '',
    className,
    onClose, }: ModalProps) {

        const onKeydown = ({key}: KeyboardEvent) => {
            switch (key) {
                case 'Escape':
                    onClose()
                    break
            }
        }
        React.useEffect(() => {
            document.addEventListener('keydown', onKeydown)
            return () => document.removeEventListener('keydown', onKeydown)
        })
        if (!visible) return null;
  return (
    <div className={ styles.modal} onClick={onClose}>
    <div className={classNames(className,styles.modalDialog)} onClick={e => e.stopPropagation()}>
            <span className={styles.modalClose} onClick={onClose}>
        &times;
      </span>
        <div className={styles.modalBody}>
            <div className={styles.modalContent}>{content}</div>
        </div>
    </div>
</div>
  )
}