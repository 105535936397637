import React, { useState, useEffect } from "react";

import "./scrollToTop.modules.scss";
import doneImage from "../../../assets/icon/tick-circle.svg";
import ModalConsultation from "../modal/ModalConsultation";

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  const [modal, setModal] = useState(false);

  const onclose = () => {
    setModal(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <ModalConsultation
        visible={modal}
        title={""}
        content={""}
        footer={undefined}
        onClose={onclose}
      />
      <div className="top-to-btm">
        {showTopBtn && (
          <div
            className="icon-position icon-style"
            // onClick={goToTop}
            onClick={() => setModal(true)}
          >
            <span className="text">получить консультацию</span>
            <img className={"done"} src={doneImage} alt={doneImage} />
          </div>
        )}
      </div>
    </>
  );
};
export default ScrollToTop;
