import { FC } from "react";
import styles from "./cardButton.module.scss"

interface ICardButtonProps {
    id?: number;
    title: string;
    subtitle: string;
};

export const CardButton: FC<ICardButtonProps> = (props) => {
    return (
        <div className= {styles.card}>
            <div className = {styles.title}>
                {props.title}
            </div>   
            <div className = {styles.subTitle}>
             {props.subtitle}
            </div>
        </div>
    );
}
