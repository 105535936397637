import React from 'react'
import styles from './Baner.module.scss'
import UI from '../UI'
import DATA from '../../data'
import { AnimationOnScroll } from 'react-animation-on-scroll'

export default function Baner() {
  return (
    
    <div className = {styles.root}>
    
        <div className={styles.containerText}>
        
        <span className={styles.preTitle}>Somoni Residence</span>
        <div className = {styles.title}>Эргономичное пространство для комфортной жизни</div>
        <div className = {styles.containerButton}>
        <div className={styles.rowButton}>
        <UI.Button  className = {styles.textButton} tiny={true}>Закрытый двор с детской площадкой</UI.Button>

          <UI.Button tiny = {true}>Просторные квартиры</UI.Button>
          </div>
          <div className={styles.rowButton}>
          <UI.Button tiny>Подземный и летний паркинги</UI.Button> 
          </div>
        </div>
       
        </div>
        <div className = {styles.CardButton}>
         { DATA.banerCardData.map(card => (
          <AnimationOnScroll animateIn="animate__fadeIn">

              <UI.CardButton key = {card.id} title = {card.title} subtitle = {card.subtitle} />
              </AnimationOnScroll>
         )  )
        }
        </div>
        <div className={styles.banner}></div>

    </div>
  )
}
