import { FC } from "react";
import styles from "./contacts.module.scss";
import DATA from "../../data";
import { Card } from "../UI/card/card";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { Formik, Form, Field } from "formik";
import image from "../../assets/img/contact.png";
import UI from "../UI";
import axios from "axios";
import Modal from "../UI/modal/Modal";
import { useState } from "react";

interface IContactsProps {
  Name: string;
  email: string;
  comment: string;
}

export const Contacts: FC<IContactsProps> = (props) => {
  const [isModal, setModal] = useState(false);
  const onClose = () => setModal(false);

  function validateEmail(value: string): string | undefined {
    let error;
    if (!value) {
      error = "Введите это поле";
    }
    return error;
  }
  function validateUsername(value: string): string | undefined {
    let error;
    if (value === "admin") {
      error = "Nice try!";
    }
    return error;
  }
  const [loading, setLoading] = useState(false);

  const defaultState = {
    center: [38.571899, 68.789481],
    zoom: 15,
  };
  return (
    <div className={styles.root}>
      <Modal
        visible={false}
        title={""}
        content={""}
        footer={undefined}
        onClose={onClose}
      />
      <span className={styles.title}>Расположение и контакты</span>
      <div className={styles.card}>
        {DATA.contactCardData.map((contact) => {
          return (
            <Card
              contact={true}
              // ssilka={contact.ssilka}
              icon={contact.icon}
              title={contact.title}
              subtitle={contact.subtitle}
            />
          );
        })}
      </div>
      <div className={styles.maps}>
        <YMaps>
          <Map width={1104} height={400} defaultState={defaultState}>
            <Placemark
              geometry={[38.567139, 68.782295]}
            />
          </Map>
        </YMaps>
      </div>
      <div id="contacts" className={styles.wrapform}>
        <div className={styles.formContainer}>
          <div className={styles.titleForm}>Связаться с нами</div>
          <Formik
            initialValues={{
              Name: "",
              email: "",
              comment: "",
            }}
            onSubmit={(values: IContactsProps) => {
              axios({
                method: "POST",
                url: "https://api.somoniresidence.tj/",
                data: values,
              })
                .then(function (res) {
                  alert("Ваше сообщение успешно отправлено");
                  setLoading(false)
                  
                })
                .catch(function (res) {
                  setLoading(false)
                });
            }}
          >
            {({ errors, touched, isValidating }) => (
              <Form className={styles.form}>
                <Field
                  placeholder="Как вас зовут?"
                  name="Name"
                  validate={validateUsername}
                />
                {errors.Name && touched.Name && <div>{errors.Name}</div>}
                <Field
                  placeholder="Email или номер телефона"
                  name="email"
                  validate={validateEmail}
                />
                {errors.email && touched.email && <div>{errors.email}</div>}
                <Field
                  heigth={160}
                  as="textarea"
                  rows={6}
                  textarea
                  placeholder="Напишите нам"
                  name="comment"
                  validate={validateUsername}
                />
                <UI.Button
                 onClick = {() => setLoading(true)}
                className={styles.btn}>
                  
                  { loading ?  "загрузка . . .   "
                  : "Отправить"}
                  </UI.Button>
              </Form>
            )}
          </Formik>
        </div>
        <img className={styles.img} src={image} alt="" />
      </div>
    </div>
  );
};
