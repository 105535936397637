import Button from './button';
import { CardButton } from './cardButton/cardButton';
import { Card } from './card/card';
import { CardApartment } from './cardApartment/cardApartment';
import { Tabs } from './tabs/tabs';
import {Slider} from './slider/Slider'
import Modal from './modal/Modal';

const UI = Object.freeze(
    Object.seal({
        Button,
        CardButton,
        Card,
        Tabs,
        CardApartment,
        Slider,
        Modal,
    })
)

export default UI;