export const interSliderData = [
    {
        id:4,
        img: '/img/inter_01.jpg',
    },
    {
        id:3,
        img: '/img/inter_02.jpg',
    },
    {
        id:1,
        img: '/img/inter_03.jpg',
    },
]