import { FC, useState } from "react";
import "./apartment.scss";
import { ITab, Tabs } from "../UI/tabs/tabs";
import { apartmentCardData } from "../../data/apartmentCardData";
import { CardApartment } from "../UI/cardApartment/cardApartment";
import DATA from "../../data";
interface IApartmentProps {}

export const Apartment: FC<IApartmentProps> = (props) => {


  const tabs: ITab[] = [
    { id: "1", label: "1-комнатные" },
    { id: "2", label: "2-комнатные" },
    { id: "3", label: "3-комнатные" },
  ];

  
  // const [modal, setModal] = useState(true)

  // const onclose  = () => {
  //     setModal(false)
  // }

  const [selectedTabId, setSelectedTabId] = useState(tabs[0].id);

  const handleTabClick = (id: string | number) => {
    setSelectedTabId(id);
  };

  return (
    <>
       
    <div id="apartments" className="root">
      <div className="title">Квартиры</div>
      <section className="TabsPage">
        <Tabs selectedId={selectedTabId} tabs={tabs} onClick={handleTabClick} />
        <div className="TabsPage-Content">
          {selectedTabId === tabs[0].id && (
            <div className="card">
              {apartmentCardData.map((apartmnet) => (
                <CardApartment
                  img={apartmnet.img}
                  title={apartmnet.title}
                  block={apartmnet.block}
                  totalRooms={apartmnet.totalRooms}
                  square={apartmnet.square}
                />
              ))}
            </div>
          )}
          {selectedTabId === tabs[1].id && (
            <div className="card">
              {DATA.apartmentSecondData.map((apartmnet) => (
                <CardApartment
                  img={apartmnet.img}
                  title={apartmnet.title}
                  block={apartmnet.block}
                  totalRooms={apartmnet.totalRooms}
                  square={apartmnet.square}
                />
              ))}
            </div>
          )}
          {selectedTabId === tabs[2].id && (
            <div className="card">
              {DATA.apartmentThreeData.map((apartmnet) => (
                <CardApartment
                  img={apartmnet.img}
                  title={apartmnet.title}
                  block={apartmnet.block}
                  totalRooms={apartmnet.totalRooms}
                  square={apartmnet.square}
                />
              ))}
            </div>
          )}
        </div>
      </section>
    </div>

    </>
  );
};
