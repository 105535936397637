import mapIcon from '../assets/icon/map.svg'
import phoneIcon from '../assets/icon/phone.svg'
import mail from '../assets/icon/mail.svg'
export const contactCardData = [
    {
        id:1,
        icon: mapIcon,
        title: 'Адрес',
        subtitle: 'г. Душанбе, ул. Бухоро, 5 (ориентир Alfemo)',
        ssilka: 'https://yandex.ru/maps/-/CCUcNQtB9D'
    },
    {
        id:2,
        icon:phoneIcon,
        title: 'Номер телефона',
        subtitle: "+992 000 000 118\n+992 000 000 119"
    },
    {
        id:3,
        icon: mail,
        title: 'Почта',
        subtitle: 'example@gmail.com'
    },
]