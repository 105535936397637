import { FC } from "react";

import "./project.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import chevronRight from "../../assets/icon/Chevron_Right_XL.png";
import chevronLeft from "../../assets/icon/Chevron_Left_XL.svg";
import "swiper/css";


interface IProjectProps {}
export const Project: FC<IProjectProps> = (props) => {
  return (
    <div id="resident" className="root">
      <div className="container">
        <div className="title">О проекте</div>
        <div className="description">
          Жилой Комплекс «Somoni Residence» находится в центре нашей столицы, по
          улице Бухоро, и представляет из себя Жилой Комплекс премиум класса,
          состоящий из 3 блоков "А", "Б" и "В" по 12 и 14 этажей. Сочетает в себе
          все важнейшие преимущества для комфортной жизни, включая: улучшенные
          планировки, развитую инфраструктуру, хорошую транспортную развязку на
          территории, а также закрытый двор с детской площадкой, благоустроенной
          придомовой территорией, круглосуточным видеонаблюдением. «Somoni
          Residence» станет местом для самых счастливых мгновений вашей семьи!
        </div>
      </div>
      <div className="slider">
        <div className="swiper-button image-swiper-button-next">
          <img className="icon" src={chevronRight} alt="" />
        </div>
        <div className="swiper-button image-swiper-button-prev">
          <img className="icon" src={chevronLeft} alt="" />
        </div>
        <Swiper
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
            disabledClass: "swiper-button-disabled",
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
        
          <SwiperSlide>
            <img
            
              className="img"
              src="/img/01.jpg"
              alt="House Somon residense"
            />
          </SwiperSlide>
          <SwiperSlide>
          <img
              className="img"
              src="/img/02.jpg"
              alt="House Somon residense"
            />
          </SwiperSlide>
          <SwiperSlide>
          <img
              className="img"
              src="/img/03.jpg"
              alt="House Somon residense"
            />
          </SwiperSlide>
          <SwiperSlide>
          <img
              className="img"
              src="/img/04.jpg"
              alt="House Somon residense"
            />
          </SwiperSlide>
          <SwiperSlide>
          <img
              className="img"
              src="/img/05.jpg"
              alt="House Somon residense"
            />
          </SwiperSlide>
          <SwiperSlide>
          <img
              className="img"
              src="/img/06.jpg"
              alt="House Somon residense"
            />
          </SwiperSlide>
          <SwiperSlide>
          <img
              className="img"
              src="/img/07.jpg"
              alt="House Somon residense"
            />
          </SwiperSlide>
          <SwiperSlide>
          <img
              className="img"
              src="/img/inter_01.jpg"
              alt="House Somon residense"
            />
          </SwiperSlide>
          <SwiperSlide>
          <img
              className="img"
              src="/img/inter_02.jpg"
              alt="House Somon residense"
            />
          </SwiperSlide>
          <SwiperSlide>
          <img
              className="img"
              src="/img/inter_03.jpg"
              alt="House Somon residense"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
