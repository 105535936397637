import {Project} from './project/project';
import { Advantages } from './advantages/advantages';
import { Layout } from './layout/layout';
import { Apartment} from './apartment/apartment';
import { Complex } from './complex/complex';
import { Contacts } from './contacts/contacts';
import {Construction} from './construction/construction'
import Footer from './core/Footer';

const Component = Object.freeze(
    Object.seal({
        Project,
        Advantages,
        Layout,
        Apartment,
        Complex,
        Construction,
        Contacts,
        Footer
    })
)
export default Component; 