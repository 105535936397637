import { FC, useCallback, useState } from "react";
import styles from "./layout.module.scss";
import { ITab, Tabs } from "../UI/tabs/tabs";
import firstLayout from "../../assets/img/layout/layout_buhoro14.png";
import Viewer from "react-viewer";
interface ILayoutProps {}

export const Layout: FC<ILayoutProps> = (props) => {
  const tabs: ITab[] = [
    { id: "1", label: "Планировка 1 этажа" },
    { id: "2", label: "Планировка 2 - 12 этаж" },
    { id: "3", label: "Планировка 13 - 14 этаж" },
  ];

  const [currentImage, setCurrentImage] = useState("");
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((source: string) => {
    setCurrentImage(source);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage("");
    setIsViewerOpen(false);
  };
  const [selectedTabId, setSelectedTabId] = useState(tabs[0].id);

  const handleTabClick = (id: string | number) => {
    setSelectedTabId(id);
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>Общая планировка </div>
      <section className={styles.TabsPage}>
        <Tabs selectedId={selectedTabId} tabs={tabs} onClick={handleTabClick} />
        <div className={styles.TabsPageContent}>
          {selectedTabId === tabs[0].id && (
            <div className={styles.containerImg}>
              <img
                className={styles.img}
                src={"/img/layout/01_layout.svg"}
                alt=""
                onClick={() => openImageViewer("/img/layout/01_layout.svg") }
              />
            </div>
          )}
          {selectedTabId === tabs[1].id && (
            <div className={styles.containerImg}>
              <img
                className={styles.img}
                src={"img/layout/12_layout.png"}
                onClick={() => openImageViewer("/img/layout/12_layout.svg") }
                alt=""
              />
            </div>
          )}
          {selectedTabId === tabs[2].id && (
            <div className={styles.containerImg}>
              <img className={styles.img} src="/img/layout/14_layout.svg"
              onClick={() => openImageViewer("/img/layout/14_layout.svg") }
              alt="" />
              
            </div>
          )}
        </div>

        {isViewerOpen && (
          <Viewer
            className={styles.img1}
            maxScale={10}
            visible={isViewerOpen}
            onClose={closeImageViewer}
            images={[{ src: currentImage, alt: "" }]}
            defaultSize={{width: 1250, height: 700}}
            noNavbar = {false}
            zoomable = {true}
            changeable = {false}
            attribute = {false}
          />
        )}
      </section>
    </div>
  );
};
