export const constructionData = [
    {
        id:1,
        icon: '/img/construction/040523.png',
        data: "02.02.2022"
    },
    {
        id:2,
        icon: '/img/construction/040523.png',
        data: "02.02.2022"
    },
    {
        id:3,
        icon: '/img/construction/040523.png',
        data: "02.02.2022"
    },
    {
        id:4,
        icon: '/img/construction/040523.png',
        data: "02.02.2022"
    },
]