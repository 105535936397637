import { FC } from "react";
import styles from "./residences.module.scss";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import UI from "../UI";
interface IModalResidencesProps {
  id?: number;
  img?: string;
  title: string;
  block: string;
  totalRooms: string;
  square: string;
  onclose: () => void
}

interface IContactsProps {
  Name: string;
  email: string;
  comment: string;
}

export const ModalResidences: FC<IModalResidencesProps> = (props) => {
  return (
    <div className={styles.root}>
      <div className={styles.left}>
      <img className={styles.img} src={props.img} alt="" />
      </div>
      <div className={styles.rigth}>
        <div className={styles.block}>
          Блок
          <div className={styles.color}>{props.block}</div>
        </div>
        <div className={styles.block}>
          Всего комнат
          <div className={styles.color}> {props.totalRooms}</div>
        </div>
        <div className={styles.block}>
          Квадратура
          <div className={styles.color}> {props.square} </div>
        </div>
        <Formik
          initialValues={{
            Name: "",
            email: "",
            comment: "apartment"
          }}
          onSubmit={(values: IContactsProps) => {
            axios({
              method: "POST",
              url: "https://api.somoniresidence.tj/",
              data: values,
            })
              .then(function (res) {
                alert("Ваше сообщение успешно отправлено.");
              })
              .catch(function (res) {
                console.log(res);
              });
          }}
        >
          {({ errors, touched, isValidating }) => (
            <Form className={styles.form}>
              <Field placeholder="Как вас зовут?" name="Name" required />
              <Field placeholder="номер телефона" name="email" required />

              <UI.Button  middle className={styles.btn}>Отправить</UI.Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
