import React, { ReactNode, ReactElement } from "react";
import styles from "./ModalConsultation.module.scss";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import UI from "..";
import done from "../../../assets/icon/tick-circle.svg"

interface ModalConsultationProps {
  visible: boolean;
  title: string;
  content: ReactElement | string;
  footer: ReactNode | string;
  onClose: () => void;
  className?: string;
}

interface IContactsProps {
  Name: string;
  email: string;
}

export default function ModalConsultation({
  visible = false,
  className,
  onClose,
}: ModalConsultationProps) {
  const [loading, setLoading] = React.useState(false);

  const onKeydown = ({ key }: KeyboardEvent) => {
    switch (key) {
      case "Escape":
        onClose();
        break;
    }
  };
  React.useEffect(() => {
    document.addEventListener("keydown", onKeydown);
    return () => document.removeEventListener("keydown", onKeydown);
  });
  if (!visible) return null;

  return (
    <div className={styles.modal} onClick={onClose}>
      <div
        className={classNames(className, styles.modalDialog)}
        onClick={(e) => e.stopPropagation()}
      >
        <span className={styles.modalClose} onClick={onClose}>
          &times;
        </span>
        <div className={styles.modalBody}>
          <div className={styles.modalContent}>
            <div className={styles.root}>
              <h2>Запишитесь на консультацию </h2>
              <p>
                Оставьте свои контакты, мы свяжемся с вами и ответим на все
                вопросы{" "}
              </p>
              <Formik
                initialValues={{
                  Name: "",
                  email: "",
                  comment: "apartment"
                }}
                onSubmit={(values: IContactsProps) => {
                  axios({
                    method: "POST",
                    url: "https://api.somoniresidence.tj/",
                    data: values,
                  })
                    .then(function (res) {
                      // alert("Successfully");
                      setLoading(false);
                      onClose();
                    })
                    .catch(function (res) {
                      console.log(res);
                      setLoading(false);
                      onClose();
                    });
                }}
              >
                {({ errors, touched, isValidating }) => (
                  <Form className={styles.form}>
                    <Field placeholder="Как вас зовут?" name="Name" required />
                    <Field placeholder="номер телефона" name="email" required />

                    <UI.Button
                      onClick= {() => setLoading(true)}
                      middle
                      className={styles.btn}
                    >
                      { loading ? "Загрузка . . ."  :  "Отправить"}
                    </UI.Button>
                  
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
