

export const apartmentCardData = [
    {
        id:1,
        img: "/img/apartment/first/01.png", 
        title: '1 комнатная',
        block: 'A',
        totalRooms: '1 комната',
        square: '71,32м²'
    },
    {
        id:2,
        img: "img/apartment/first/02.png", 
        title: '1 комнатная',
        block: 'A',
        totalRooms: '1 комната',
        square: '71,32м²'
    },
    // {
    //     id:3,
    //     img: "img/apartment/first/03.png", 
    //     title: '1 комнатная',
    //     block: 'A',
    //     totalRooms: '1 комната',
    //     square: '50,00м²'
    // },
    {
        id:4,
        img: "img/apartment/first/04.png", 
        title: '1 комнатная',
        block: 'Б',
        totalRooms: '1 комната',
        square: '61,70м²'
    },
    {
        id:5,
        img: "img/apartment/first/05.png", 
        title: '1 комнатная',
        block: 'В',
        totalRooms: '1 комната',
        square: '71,32м²'
    },
    {
        id:6,
        img: "img/apartment/first/06.png", 
        title: '1 комнатная',
        block: 'В',
        totalRooms: '1 комната',
        square: '55,00м²'
    },
    {
        id:7,
        img: "img/apartment/first/07.png", 
        title: '1 комнатная',
        block: 'В',
        totalRooms: '1 комната',
        square: '55,00м²'
    },
    {
        id:8,
        img: "img/apartment/first/08.png", 
        title: '1 комнатная',
        block: 'В',
        totalRooms: '1 комната',
        square: '71,32м²'
    },
    {
        id:9,
        img: "img/apartment/first/09.png", 
        title: '1 комнатная',
        block: 'А',
        totalRooms: '1 комната',
        square: '55,00м²'
    },
]