import { FC } from "react";
import './slider.scss'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import chevronRight from '../../../assets/icon/Chevron_Right_XL.png';
 import chevronLeft from '../../../assets/icon/Chevron_Left_XL.svg';
import "swiper/css";
interface ISliderProps {
    id?: number;
    img: IImg[];
    title: string;
};

interface IImg {
    id?: number;
    img: string; 
}

export const Slider: FC<ISliderProps> = (props) => {
    return (
        <div className={"Contaierslider"}>
        <div className={ `swiperButton image-swiperButton-next${props.title}`}>
          <img className="icon" src={chevronRight} alt="" />
        </div>
        <div className={ `swiperButton image-swiperButton-prev${props.title}`}>
        <img  className="icon" src={chevronLeft} alt="" />
        </div>
               <Swiper
          navigation={{
            nextEl: `.image-swiperButton-next${props.title}`,
            prevEl: `.image-swiperButton-prev${props.title}`,
            disabledClass: "swiperButton-disabled"
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
            {props.img.map((item, index) => {
                return (
                    <SwiperSlide key={index}>
                        <div className="swiper-slide">
                            <img src={item.img} alt="" />
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
        </div>
    );
}
