
export const complexSliderData = [
    {
        id:4,
        img: '/img/04.jpg',
    },
    {
        id:3,
        img: '/img/03.jpg',
    },
    {
        id:1,
        img: '/img/01.jpg',
    },
]