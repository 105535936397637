

export const apartmentThreeData = [
    {
        id:1,
        img: "/img/apartment/three/01.png", 
        title: '3 комнаты',
        block: 'A',
        totalRooms: '3 комнаты',
        square: '119,10м²'
    },
    {
        id:2,
        img: "img/apartment/three/02.png", 
        title: '3 комнаты',
        block: 'A',
        totalRooms: '3 комнаты',
        square: '107,89м²'
    },
    {
        id:3,
        img: "img/apartment/three/03.png", 
        title: '3 комнаты',
        block: 'Б',
        totalRooms: '3 комнаты',
        square: '128,52м²'
    },
    {
        id:4,
        img: "img/apartment/three/04.png", 
        title: '3 комнаты',
        block: 'B',
        totalRooms: '3 комнаты',
        square: '107,89м²'
    },
    {
        id:5,
        img: "img/apartment/three/05.png", 
        title: '3 комнаты',
        block: 'B',
        totalRooms: '3 комнаты',
        square: '119,10м²'
    },

]