export const banerCardData = [
    {
        id:1,
        title: '1-комнатные',
        subtitle: 'от 55 м²'
    },
    {
        id:2,
        title: '2-комнатные',
        subtitle: 'от 67,40 м²'
    },
    {
        id:3,
        title: '3-комнатные',
        subtitle: 'от 107,89 м²'
    },
]