import { Link } from "react-scroll";
import styles from "./footer.module.scss";
import logoSomon from "../../../assets/logo.svg";
import boLogo from "../../../assets/BO.svg";
import facebookIcon from "../../../assets/icon/facebook.svg";
import instagramIcon from "../../../assets/icon/instagram.svg";
import telegramIcon from "../../../assets/icon/telegram.svg";
const Footer = () => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.container}>
          <img width="86" className={styles.logoSomon} src={logoSomon} alt="" />
          <ul className={styles.horizontal}>
            <li>
              <Link
                className={styles.link}
                activeClass="active"
                to="gallery"
                spy={true}
                smooth={true}
                offset={-70}
                duration={600}
              >
                О жилом комплексе
              </Link>
            </li>
            <li>
              <Link
                className={styles.link}
                activeClass="active"
                to="advantages"
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
              >
                Преимущества
              </Link>
            </li>
            <li>
              <Link
                className={styles.link}
                activeClass="active"
                to="apartments"
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
              >
                Квартиры
              </Link>
            </li>
            <li>
              <Link
                className={styles.link}
                activeClass="active"
                to="gallery"
                spy={true}
                isDynamic={true}
                smooth={true}
                offset={-70}
                duration={1200}
              >
                Галерея
              </Link>
            </li>
            <li>
              <Link
                className={styles.link}
                activeClass="active"
                to="contacts"
                spy={true}
                isDynamic={true}
                smooth={true}
                offset={-70}
                duration={1200}
              >
                Контакты
              </Link>
            </li>
          </ul>
          <div className={styles.social}>
            <a
              target="_blank"
              href="https://www.facebook.com/somoni.residence/"
              rel="noreferrer"
            >
              <img src={facebookIcon} alt="" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/somoni.residence/"
              rel="noreferrer"
            >
              <img src={instagramIcon} alt="" />
            </a>
            <a
              target="_blank"
              href="https://t.me/somoniresidence"
              rel="noreferrer"
            >
              <img width={24} src={telegramIcon} alt="" />
            </a>
          </div>
        </div>
        <hr className={styles.dashed}></hr>
        <div className={styles.logo}>
          <a target="_blank" href="https://bobo.tj/" rel="noreferrer">
            <img src={boLogo} alt="" />{" "}
          </a>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Footer;
