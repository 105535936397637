import './styles/globals.scss';
import DesktopLayout from './layouts/Desktop/DesktopLayout';
import Header from './components/core/Header/Header';
import Baner from './components/Baner/Baner';
import Component from './components';
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import ScrollToTop from './components/UI/scrollToTop/scrollToTop';
function App() {
  
  return (
    <>  
    
      <DesktopLayout>

      <Header/>
      
      <Baner/>
      <AnimationOnScroll animateIn="animate__zoomIn">
      <Component.Project/>
      </AnimationOnScroll>
      <Component.Advantages/>
      <AnimationOnScroll animateIn="animate__fadeIn">
      <Component.Layout/>
      </AnimationOnScroll>
      {/* <AnimationOnScroll animateIn="animate__zoomIn"> */}
      <Component.Apartment/>
      {/* </AnimationOnScroll> */}
      <Component.Complex/>
       {/* <Component.Construction/> */}
      <AnimationOnScroll animateIn="animate__zoomIn">
      <Component.Contacts Name='' email='' comment=''/>
      </AnimationOnScroll>
      </DesktopLayout>
      <Component.Footer/>
      <ScrollToTop/>
    </>
  );
}

export default App;
